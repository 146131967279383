import React, { useMemo } from 'react'
import { ProjectMemberRole } from '../../../graphql/generated'

import { Project } from "../types"
import { sortByCreated } from "../../../common/utils/sort"


export type ContractorTotalProps = {
  project: Project,
}
const ContractorTotal: React.FC<ContractorTotalProps> = ({ project }) => {
  const contractorSlots = useMemo(() => project.memberSlots.filter(x => x.requiredProjectRole === ProjectMemberRole.CandidateProfessional).sort(sortByCreated), [ project.memberSlots ])
  const professionalMembers = useMemo(() => project.members.filter(x => [ ProjectMemberRole.CandidateProfessional ].includes(x.projectRole)), [ project.memberSlots ])

  const total = contractorSlots.length + professionalMembers.length
  return (
    <div>{total} ({professionalMembers.length} found)</div>
  )
}

export default ContractorTotal
