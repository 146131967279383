import { Chip } from '@mui/material'
import React from 'react'
import { Team, TeamType } from '../../graphql/generated'
import EngineeringIcon from '@mui/icons-material/Engineering'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import HouseIcon from '@mui/icons-material/House'
import PersonIcon from '@mui/icons-material/Person'

const getIcon = (teamType: TeamType) => {
  switch (teamType) {
    case TeamType.Contractor:
      return <EngineeringIcon />
    case TeamType.Architect:
      return <SquareFootIcon />
    case TeamType.Homeowner:
      return <HouseIcon />
    default:
      return <PersonIcon />
  }
}



export const TeamTypeChip: React.FC<{team: Pick<Team, "type"|"name" >}> = ({ team }) => {
  const { type } = team
  return <Chip label={type} icon={getIcon(type)} />
}

export default TeamTypeChip
