import { useContext } from "react"
import { AuthContext, EnvContext } from "../../Bootstrapper"

type TUseGraphQLDataSourceReturns = {
  endpoint: string,
  fetchParams?: RequestInit,
}

export const useCoreApiSource = (): TUseGraphQLDataSourceReturns => {
  const auth = useContext(AuthContext)
  const env = useContext(EnvContext)

  if (!auth || !env) {
    throw new Error("useCoreApiSource can only be used inside of a ready context")
  }

  const Authorization = auth.token

  return {
    endpoint: env.REACT_APP_CORE_URL,
    fetchParams: {
      headers: {
        Authorization,
        // The Content-Type header is needed for MockServiceWorker, but not AppSync
        'Content-Type': 'application/json',
      },
    },
  }
}
