import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { GridColDef } from '@mui/x-data-grid/models/colDef'
import React, { useCallback, useMemo } from 'react'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { ListContractorSuggestionsForProjectQuery, ProjectMemberRole, useAddProjectInviteMutation, useListContractorSuggestionsForProjectQuery } from '../../../graphql/generated'
import AddIcon from "@mui/icons-material/Add"
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded'
import { InviteTeamToProjectState } from '../types'
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { ProjectsIndexContext } from '../ProjectsIndexContext'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Styles from "./BuilderSuggestionList.module.scss"
import Tooltip from '@mui/material/Tooltip'
import { EnvContext } from '../../../Bootstrapper'
import { Link } from '@mui/material'

type ContractorProfile = ListContractorSuggestionsForProjectQuery["listContractorSuggestionsForProject"][0];

type BuilderSuggestionActionButtonProps = {
  team: ContractorProfile,
  onClick: (team: ContractorProfile) => unknown,
}
const BuilderSuggestionActionButton: React.FC<BuilderSuggestionActionButtonProps> = ({ onClick , team }) => {
  const handleClick = useCallback(() => onClick(team), [ onClick, team ])
  return <Button variant='outlined' color="success" onClick={handleClick}><AddIcon />Invite</Button>
}

type BuilderSuggestionListProps = {
  modalState: InviteTeamToProjectState,
}

const BuilderSuggestionName: React.FC<{contractorProfile: ContractorProfile}> = ({ contractorProfile }) => {
  const name = contractorProfile.companyTradingAs || contractorProfile.companyRegisteredName
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)

  const contractorUrl = `${REACT_APP_APP_URL}/profile/CONTRACTOR/${contractorProfile.id}`
  return (<Box>
    <Typography><Link target="_blank" href={contractorUrl}>{name}</Link></Typography>
    {contractorProfile.companyNumber &&
      <Tooltip title="Company Number"><Typography className={Styles.secondaryInfo} variant="body2" color={"text.secondary"} ><BusinessCenterRoundedIcon />{contractorProfile.companyNumber}</Typography></Tooltip>
    }
  </Box>)
}

const BuilderSuggestionList: React.FC<BuilderSuggestionListProps> = ({ modalState: { project } }) => {
  const gqlDatasource = useCoreApiSource()
  const { handleModalSuccess } = useRequiredContext(ProjectsIndexContext)

  const query = useListContractorSuggestionsForProjectQuery(gqlDatasource, { projectId: project.id }, { refetchOnWindowFocus: false })
  const addProjectInvite = useAddProjectInviteMutation(gqlDatasource)

  const handleAdd = async (team: ContractorProfile) => {
    await addProjectInvite.mutateAsync({ projectId: project.id, teamId: team.id, projectMemberRole: ProjectMemberRole.CandidateProfessional })
    handleModalSuccess(project.id)
  }

  const columns = useMemo<GridColDef<ContractorProfile>[]>(() => [
    { sortable: false, flex: 1, minWidth: 200, field: 'companyRegisteredName', headerName: 'Name', renderCell: (params) => <BuilderSuggestionName contractorProfile={params.row} /> },
    { sortable: false, maxWidth: 125, field: 'acceptedLeads', valueFormatter: (params) => (params.value || 0), headerName: 'Accepted Leads' },
    { sortable: false, maxWidth: 125, field: 'rejectedLeads', valueFormatter: (params) => (params.value || 0), headerName: 'Rejected Leads' },
    { sortable: false, flex: 1, field: 'actions', headerName: 'Actions', align: "right", renderCell: (params) => <BuilderSuggestionActionButton team={params.row} onClick={handleAdd} /> },
  ], [])

  const data = query.data?.listContractorSuggestionsForProject || []

  return (
    <>
      <DialogTitle>Invite Contractor to Project</DialogTitle>
      <DialogContent>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={100}
          disableSelectionOnClick={true}
          autoHeight={true}
          disableColumnMenu
          hideFooterPagination
          hideFooter
        />
      </DialogContent>
    </>
  )
}

export default BuilderSuggestionList
