import { useCallback, useEffect, useRef, useState } from "react"
import createAuth0Client, { Auth0Client, User } from '@auth0/auth0-spa-js'
import { EnvShape, requireEnv } from "./config"
import React from "react"
import App from "./App"
import { QueryClient, QueryClientProvider } from "react-query"
import { GetMyIndividualQuery, useGetMyIndividualQuery } from "./graphql/generated"
import Button from '@mui/material/Button'
import RefreshIcon from "@mui/icons-material/Refresh"

function requireRef<T>(x: React.MutableRefObject<T>): NonNullable<T> {
  if (!x.current) {
    throw new Error("missing guarded value")
  }

  // TODO: this won't cast, and i don't know why
  return x.current as NonNullable<T>
}

type BootstrapState = "initial" | "inprogress" | "ready" | "error" | "loggedout" | "unauthorized"

export type AuthData = {
  auth0: Auth0Client,
  token: string,
  individual: NonNullable<GetMyIndividualQuery["getMyIndividual"]>,
}
export const AuthContext = React.createContext<AuthData| null>(null)
export const EnvContext = React.createContext<EnvShape| null>(null)
export const IndividualContext = React.createContext<EnvShape| null>(null)

const CenterContainer: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  return (
    <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      {children}
    </div>
  )
}

// https://dev.to/ag-grid/react-18-avoiding-use-effect-getting-called-twice-4i9e
export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>()
  const effectCalled = useRef(false)
  const renderAfterCalled = useRef(false)
  const [ , setVal ] = useState<number>(0)

  if (effectCalled.current) {
    renderAfterCalled.current = true
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect()
      effectCalled.current = true
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1)

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return
      }
      if (destroyFunc.current) {
        destroyFunc.current()
      }
    }
  }, [])
}


const Bootstrapper: React.FC = () => {
  const [ bootstrapState, setBootstrapState ] = useState<BootstrapState>("initial")

  const auth0Client = useRef<Auth0Client>()
  const auth0Token = useRef<string>()
  const auth0User = useRef<User>()
  const queryClient = useRef<QueryClient>()
  const processEnv = useRef<EnvShape>()
  const myIndividual = useRef<GetMyIndividualQuery["getMyIndividual"]>()
  const loginDone = useRef<(value: unknown) => unknown>()

  const handleSignin = useCallback(async () => {
    const client = requireRef(auth0Client)
    const done = requireRef(loginDone)

    await client.loginWithPopup()
    done(true)
  }, [ loginDone.current, auth0Client.current ])

  const bootstrap = async () => {
    try {
      if (bootstrapState !== "initial") return
      setBootstrapState("inprogress")

      const env = requireEnv()
      const qClient = new QueryClient()

      processEnv.current = env
      queryClient.current = qClient

      const client = await createAuth0Client({
        domain: env.REACT_APP_AUTH0_DOMAIN,
        client_id: env.REACT_APP_AUTH0_CLIENT,
        redirect_uri: env.REACT_APP_DASHBOARD_URL,
        audience: env.REACT_APP_AUTH0_AUDIENCE,
      })

      auth0Client.current = client
      const isAuthenticated = await client.isAuthenticated()
      // if not authenticated, wait until logged in
      if (!isAuthenticated) {
        console.debug("[bootstrap] - not authenticated, waiting for user login")
        setBootstrapState("loggedout")
        await new Promise((resolve) => {
          loginDone.current = resolve
        })
      }

      console.debug("[bootstrap] getting token...")
      const [ token, user ] = await Promise.all([ client.getTokenSilently(), client.getUser() ])
      if (!user) throw new Error("Missing User")

      const dataSource = {
        endpoint: env.REACT_APP_CORE_URL,
        fetchParams: {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      }

      const myIndividualResponse = await qClient.fetchQuery(useGetMyIndividualQuery.getKey(), useGetMyIndividualQuery.fetcher(dataSource))

      const individual = myIndividualResponse.getMyIndividual
      if (!individual) throw new Error("Missing Individual")

      myIndividual.current = individual
      auth0Token.current = token
      auth0User.current = user

      if (!user.email?.endsWith("@weaver.build")) {
        console.debug(`[bootstrap] email ${user.email} not authorized`)
        setBootstrapState("unauthorized")
        return
      }

      setBootstrapState("ready")
    } catch (e) {
      console.error(`Error during bootstrap ${e}`)
      setBootstrapState("error")
    }
  }

  useEffectOnce(() => {
    bootstrap()
  })

  console.log(bootstrapState)
  if ([ "initial", "inprogress" ].includes(bootstrapState)) {
    return <CenterContainer>
      <div>
        <p>Loading...</p>
      </div>
    </CenterContainer>

  } else if (bootstrapState === "loggedout") {
    return <CenterContainer>
      <div>
        <p>Please sign in to continue</p>
        <Button color="success" onClick={handleSignin}>Sign In</Button>
      </div>
    </CenterContainer>

  } else if (bootstrapState === "unauthorized") {
    const client = requireRef(auth0Client)
    const user = requireRef(auth0User)
    return <CenterContainer>
      <div>
        <p>You are not authorized to use this application.</p>
        <p>Signed In as {user.email}</p>
        <Button onClick={() => client.logout()} variant="outlined">Sign Out</Button>
      </div>
    </CenterContainer>

  } else if (bootstrapState === "error") {
    return <CenterContainer>
      <div>
        <p>Something went wrong, please refresh</p>
        <Button onClick={() => window.location.reload()}><RefreshIcon />refresh</Button>
      </div>
    </CenterContainer>

  } else if ( bootstrapState === "ready" ) {
    const authContext = {
      auth0: requireRef(auth0Client),
      token: requireRef(auth0Token),
      individual: requireRef(myIndividual),
    }
    return (
      <EnvContext.Provider value={requireRef(processEnv)}>
        <QueryClientProvider client={requireRef(queryClient)}>
          <AuthContext.Provider value={authContext}>
            <App />
          </AuthContext.Provider>
        </QueryClientProvider>
      </EnvContext.Provider>
    )
  } else {
    throw new Error(`Unknown AppState ${bootstrapState}`)
  }
}

export default Bootstrapper
