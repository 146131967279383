import { ListAllProjectsFilter, ListAllProjectsQuery, useListAllProjectsQuery, useAddProjectSlotMutation, useRefreshSingleProjectQuery, ProjectMemberRole, TeamType } from '../../graphql/generated'
import { ModalState, Project, ProjectMember, ProjectMemberInvite, ProjectMemberSlot } from "./types"
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { useSearchState } from '../../common/hooks/pages'
import React, { useMemo, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import TaskStatus from './widgets/TaskStatusDisplay'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import ContractorDisplay from './widgets/ContractorDisplay'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { DateTime } from "luxon"
import { useDebounce } from 'use-debounce'
import CircularProgress from '@mui/material/CircularProgress'
import { useQueryClient } from 'react-query'
import { ProjectsIndexContext, ProjectsIndexContextValue } from './ProjectsIndexContext'
import { ManageMember } from './dialogs/ManageMember'
import { ManageSlot } from './dialogs/ManageSlot'
import { ManageInvite } from './dialogs/ManageInvite'
import BuilderSuggestionList from './dialogs/BuilderSuggestionList'
import Dialog from '@mui/material/Dialog'
import Chip from '@mui/material/Chip'
import Styles from "./ProjectsIndex.module.scss"
import ContractorTotal from './widgets/ContractorTotal'
import OwnerDisplay from './widgets/OwnerDisplay'


const ProjectsIndex: React.FC = () => {
  const queryClient = useQueryClient()

  const gqlDatasource = useCoreApiSource()
  const addSlot = useAddProjectSlotMutation(gqlDatasource)
  const [ searchState, mergeNext ] = useSearchState<ListAllProjectsFilter>({})
  const [ debouncedSearch ] = useDebounce(searchState, 500)

  const query = useListAllProjectsQuery(gqlDatasource, {
    filter: debouncedSearch,
  }, { refetchOnWindowFocus: false })

  const [ manageModalState, setManageModalState ] = useState<ModalState|undefined>(undefined)
  const handleManageMember = (project: Project, member: ProjectMember) => setManageModalState({ type: "member", project, member })
  const handleManageInvite = (project: Project, invite: ProjectMemberInvite) => setManageModalState({ type: "invite", project, invite })
  const handleManageSlot = (project: Project, slot: ProjectMemberSlot) => setManageModalState({ type: "slot", project, slot })
  const handleManageAddProjectInvite = (project: Project) => setManageModalState({ type: "addProjectInvite", project })

  const refreshSingleProject = async (projectId: string) => {
    console.debug(`[ProjectsIndex] refreshing project ${projectId}`)
    const old = queryClient.getQueryData<ListAllProjectsQuery>(useListAllProjectsQuery.getKey({ filter: debouncedSearch }))

    const { getProject: refreshedProject } = await queryClient.fetchQuery(useRefreshSingleProjectQuery.getKey({ projectId }), useRefreshSingleProjectQuery.fetcher(gqlDatasource, { projectId }))
    
    queryClient.setQueryData<ListAllProjectsQuery>(useListAllProjectsQuery.getKey({ filter: debouncedSearch }), () => {
      const nextData = [ ...old?.listAllProjects || [] ]
      const updatedIx = nextData.findIndex(x => x.id === refreshedProject.id)

      if (updatedIx === -1) {
        nextData.push(refreshedProject)
      } else {
        nextData.splice(updatedIx, 1, refreshedProject)
      }
      console.debug(old, nextData, updatedIx)

      return {
        ...old,
        listAllProjects: nextData,
      }
    })
  }

  /** called when we exit a modal that we _know_ has updated the project */
  const handleModalSuccess = async (projectId: string) => {
    setManageModalState(undefined)
    await refreshSingleProject(projectId)
  }
  const handleModalCancel = () => setManageModalState(undefined)

  const handleAddContractorSlot = async (project: Project) => {
    await addSlot.mutateAsync({ input: { projectId: project.id, requiredProjectRole: ProjectMemberRole.CandidateProfessional, requiredTeamType: TeamType.Contractor } })
    await refreshSingleProject(project.id)
  }

  const indexContext: ProjectsIndexContextValue = {
    handleManageInvite, 
    handleManageMember,
    handleManageSlot,
    handleManageAddProjectInvite,
    handleAddSlot: handleAddContractorSlot,
    handleModalSuccess,
    handleModalCancel,
  }

  const columns = useMemo<GridColDef<Project>[]>(() => [
    { sortable: false, flex: 1, minWidth: 200, field: 'title', headerName: 'Project' },
    { sortable: false, flex: 1, maxWidth: 400, minWidth: 100, field: 'projectType', headerName: 'Type', renderCell: (params) => <div className={Styles.projectType}>{params.row.projectTypes.sort().map(pt => <Chip key={pt} label={pt} />)}</div> },
    { sortable: false, flex: 1, maxWidth: 200, minWidth: 150, field: 'status', headerName: 'Status', renderCell: (params) => <TaskStatus project={params.row} /> },
    { sortable: false, flex: 1, maxWidth: 100, field: 'documentCount', headerName: 'Documents', renderCell: (params) => (params.row.documentCount || 0) },
    { sortable: false, flex: 1, maxWidth: 180, field: 'builderCount', headerName: 'Weaver Builders to Find', renderCell: (params) => <ContractorTotal project={params.row} /> },
    { sortable: false, flex: 1, minWidth: 250, field: 'builders', headerName: 'Builders', renderCell: (params) => <ContractorDisplay project={params.row} /> },
    { sortable: false, flex: 1, minWidth: 250, field: 'owners', headerName: 'Owners', renderCell: (params) => <OwnerDisplay project={params.row} /> },
    { sortable: false, width: 200, field: 'lastActivity', headerName: 'Last Activity', valueFormatter: (params) =>  DateTime.fromISO(params.value).toRelativeCalendar() },
  ], [])
  
  const data = query.data?.listAllProjects || []

  return (
    <ProjectsIndexContext.Provider value={indexContext}>
      <Grid container rowSpacing={2} p={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container rowSpacing={2}>
                <Grid item xs={6}>
                  <TextField value={searchState.titleContains || ""} onChange={(e) => mergeNext({ titleContains: e.target.value })} label="Search" variant="outlined">
                  </TextField>
                  { query.isLoading && <CircularProgress /> }
                </Grid>
                <Grid item xs={6}>
                  {!!query.error && (
                    <Alert severity="error">
                      <AlertTitle>Error loading projects list</AlertTitle>
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    pageSize={20}
                    disableSelectionOnClick={true}
                    rowHeight={110}
                    autoHeight={true}
                    disableColumnMenu
                    hideFooterPagination
                    hideFooter
                  />     
                </Grid>  
              </Grid>   
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={!!manageModalState}
        onClose={handleModalCancel}
        fullWidth={manageModalState?.type === "addProjectInvite"}
      >
        <>
          {manageModalState?.type === "member" && <ManageMember modalState={manageModalState}/> }
          {manageModalState?.type === "invite" && <ManageInvite modalState={manageModalState}/> }
          {manageModalState?.type === "slot" && <ManageSlot modalState={manageModalState}/> }
          {manageModalState?.type === "addProjectInvite" && <BuilderSuggestionList modalState={manageModalState}/> }
        </>
      </Dialog>
    </ProjectsIndexContext.Provider>

  )
}

export default ProjectsIndex
